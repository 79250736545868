import Vivus from "vivus";
import { qsa, docReady } from "@js/libs/helpers";

docReady( () => {

	const _uuid = ( () => {
		let uid = 0;
		return () => {
			uid += 1;
			return uid;
		};
	})();

	const vivii = {};

	// const fn = entries => {
	// 	entries.forEach( ({ isIntersecting, target }) => {

	// 		if (isIntersecting) {
	// 			const uid = target.dataset.uid;
	// 			if ( uid && vivii[ uid ] ) {
	// 				vivii[uid].play();
	// 			}
	// 		} else {				
	// 			observer.unobserve( target );
	// 		}

	// 	});
	// };

	// const observer = new IntersectionObserver( fn, {
	// 	rootMargin: '0px 0px 100px 0px'
	// });

	qsa('[data-vivus]', e => {

		const type = e.getAttribute('data-vivus');

		const uid = _uuid();
		const svg = e.querySelector('svg');
		svg.dataset.uid = uid;
		vivii[ uid ] = new Vivus( svg, {
			type,
			duration: 100,
		} )

		// observer.observe( target );

	});

});