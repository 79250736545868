import { docReady, qsa } from "@js/libs/helpers";
import { media } from "@js/parts/media";

docReady(() => {

	let isLarge = media.matches('md');

	qsa('[data-categories-toggle]', button => {

		const menu = button.nextElementSibling;
		let menuHidden = true;

		if ( !menu ) {
			return;
		}

		let timer;

		const showHide = ( b ) => {
			menu.setAttribute('aria-hidden', b ? 'true' : 'false' );
			button.setAttribute('aria-expanded', b ? 'false' : 'true' );

			clearTimeout( timer );
			if ( !b ) {
				timer = setTimeout( () => {
					menu.classList.add( 'fadein' );
					menu.classList.remove( 'fadeout' );
				}, 100 );
			} else {
				menu.classList.remove( 'fadein' );
				menu.classList.add( 'fadeout' );
			}

		};

		const calc = () => {
			if (media.matches('md')) {
				isLarge = true;
				button.setAttribute('aria-hidden', 'true');
				showHide( false );
			} else {
				isLarge = false;
				showHide( menuHidden );
				button.setAttribute('aria-hidden', 'false');
			}
		};

		calc();
		window.addEventListener('resize', calc);

		button.addEventListener('click', e => {
			menuHidden = !menuHidden;
			showHide( menuHidden );
		});

	});

});