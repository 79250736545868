export default function(opts = {}) {

    return {
        handle: null,
        title: null,
        categories: null,
        isCurrentPage: null,
        buttonHover: false,

        init() {
            Object.keys(opts).forEach(o => {
               this[o] = opts[o];
            });
            window.addEventListener('keydown', e => this.onClose(e));
        },

        get isActive() {
            return this.workFiltersActive === this.handle;
        },

        get columns() {

            if ( this.categories.length > 21 ) {

                let chunk = Math.ceil( this.categories.length / 3 );

                let cols = [];

                let col1 = [ ...this.categories ];


                while ( col1.length ) {
                    cols.push( col1.splice( 0, chunk ) );
                }

                return cols;


            } else if ( this.categories.length > 10 ) {

                let chunk = Math.ceil( this.categories.length / 2 );

                let col2 = [ ...this.categories ];
                let col1 = col2.splice( 0, chunk );

                return [
                    col1,
                    col2
                ];
            }

            return [ this.categories ];
        },

        onClose(e) {
            if (this.workFiltersActive && e.key === 'Escape') {
                this.workFiltersActive = false;
            }
        },

        hide() {
            this.workFiltersActive = false;
        },

        show() {
            this.workFiltersActive = this.handle;
        },

        toggle() {
            if ( this.isActive ) {
                return this.hide();
            }

            return this.show();

        }
    }

}