export default function() {

    return {

        show: false,

        init() {
           this.$watch('show', val => {
               const b = document.querySelector('body');
               if ( this.show ) {
                   b.classList.add('locked');
                   b.classList.add('overflow-hidden');
                   this.shared.overlayShowing = true;
               } else {
                   this.shared.overlayShowing = false;
                   b.classList.remove('overflow-hidden');
                   b.classList.remove('locked');
               }
           });

           window.addEventListener('keydown', e => this.onClose(e));
        },

        onClose(e) {
            if (this.show && e.key === 'Escape') {
                this.show = false;
            }
        }

    }
}