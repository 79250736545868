/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/what-input";
import "@js/parts/lazyloading";

import "@js/parts/setup-card-click";
import "@js/parts/responsive-bg";
import "@js/parts/misc";
import "@js/parts/transitions";
import "@js/parts/svg-draw-in";
import "@js/parts/alpine/index";

import { initHeader } from "@js/parts/sticky-header";
initHeader();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}