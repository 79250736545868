import { media } from "@js/parts/media";

export default function(images) {

    return {
        images: null,
        debug: false,
        active: 0,
        timer: false,
        playing: false,
        waiting: false,
        restartTimer: false,
        mobile: false,

        init() {
            if(!images) return;
            this.images = images;
            window.addEventListener('resize', this.calc);
            this.calc();
            this.start();
        },

        next( pause ) {
            let newI = this.active + 1;
            if ( newI >= this.images.length ) {
                newI = 0;
            }

            if ( pause ) {
                this.wait();
            }

            this.select(newI);

        },

        prev( pause ) {
            let newI = this.active - 1;
            if ( newI < 0 ) {
                newI = this.images.length - 1;
            }

            if ( pause ) {
                this.wait();
            }

            this.select(newI);

        },

        pause() {
            this.waiting = false;
            this.playing = false;
            clearInterval( this.timer );
            clearTimeout( this.restartTimer );
        },

        select(i, pause) {

            if ( pause ) {
                this.wait();
            }

            this.active = i;
        },

        wait(i) {
            this.pause();
            this.active = i;
            this.waiting = true;
            this.restartTimer = setTimeout( () => {
                this.waiting = false;
                this.start();
            }, 8000 );
        },

        start() {

            // Clear any intervals or timers:
            this.pause();
            this.playing = true;
            this.timer = setInterval( () => {

                clearTimeout( this.restartTimer );
                this.next();

            }, 3500 );
        },

        calc() {
            if ( media.matches('lg') ) {
                this.mobile = false;
            } else {
                this.mobile = true;
            }
        },

        follow(link) {
            if (!link) {
                return;
            }

            window.open(link, "_blank");
        },
    }

}