import { qsa, docReady } from "@js/libs/helpers";
import cardClick from "@js/libs/card-click";

docReady(() => {

	qsa('[data-link-cover]', card => {
		const link = card.querySelector('[data-link-target]');
		cardClick(card, link);
	});

});