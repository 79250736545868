import axios from "axios";

export default function(opts = {}) {
    return {
        loading: false,
        entries: [],
        page: 1,
        nextPage: false,
        category: false,

        init() {
            this.page = opts.page || 1;
            this.category = opts.category || false;
        },

        buildParams() {
            return {
                page: this.page,
                category: this.category,
            }
        },

        async loadMore() {

            this.page = this.page + 1;

            this.loading = true;

            const { data: response } = await axios.get('/news.json', {
                params: this.buildParams()
            })
                .catch(err => {
                    this.loading = false;
                    console.error("Couldn't get posts");
                });

            this.loading = false;

            if (!response) {
                console.error(response);
                console.error("Couldn't get posts");
                return;
            }

            const { data, meta } = response;

            if (data && data.length) {
                this.entries = [...this.entries, ...data];
            }

            this.page = meta.pagination.current_page;
            this.loading = false;

        }
    }
}