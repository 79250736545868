export default function ( card, link ) {
	let down;
	let up;
	let meta;

	const onDown = (e) => {
		if ( e.target.tagName.toLowerCase() === "a" || e.target.tagName.toLowerCase() === "button" ) {
			return;
		}

		if ( e.which === 2 || e.ctrlKey || e.metaKey ) {
			meta = true;
		} else {
			meta = false;
		}

		down = +new Date();
	};

	const onUp = (e) => {
		up = +new Date();
		if ((up - down) < 200) {
			if ( meta ) {
				const ogTarget = link.getAttribute('target');
				link.setAttribute('target', '_BLANK');
				link.click();
				if ( ogTarget ) {
					link.setAttribute('target', ogTarget );
				} else {
					link.removeAttribute('target', ogTarget );
				}
			} else {
				link.click();
			}
		}
	};

	card.style.cursor = 'pointer';
	card.onmousedown = onDown;
	// card.ontouchstart = onDown;
	card.onmouseup = onUp;
	// card.ontouchend = onUp;
};