import axios from "axios";
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default function() {

    return {
        loading: false,
        content: "",
        show: false,
        forceButton: false,
        isMobile: false,

        init() {

            window.addEventListener('keydown', e => this.onClose(e));

            this.$watch('personVisible', (now, then) => {
                if ( now ) {
                    requestAnimationFrame( () => {
                        this.show = true;
                    });
                } else {
                    this.dismiss();
                }
            });

            this.$watch('show', () => {
                if ( this.show ) {
                    document.body.classList.add('overflow-hidden');
                } else {
                    document.body.classList.remove('overflow-hidden');
                }
            });

            this.$watch('personUrl', newUrl => {
                if ( newUrl ) {
                    this.fetchPerson( newUrl );
                }
            });

            this.$watch('content', () => {
                this.$nextTick( () => {
                    const buttons = this.$refs.content.querySelectorAll('button[data-dismiss]');

                    if ( buttons.length ) {
                        this.forceButton = false;
                        buttons.forEach( b => {
                            const remove = () => {
                                this.dismiss();
                                b.removeEventListener('click', remove );
                            };
                            b.addEventListener('click', remove );
                        });
                    } else {
                        this.forceButton = true;
                    }
                });
            });
        },

        get position() {
            const w = Math.max( window.innerWidth, window.innerHeight );

            const { backgroundImage, backgroundSize, backgroundColor } = APP.config.personOverlayStyles || {
                backgroundImage: 'none',
                backgroundSize: '16px auto',
                backgroundColor: '#A6DFBF',
            };

            return {
                opacity: this.show ? 0.95 : 0,
                backgroundImage,
                backgroundSize,
                backgroundColor,
            };
        },

        onClose(e) {
            if (this.show && e.key === 'Escape') {
                this.dismiss();
            }
        },

        dismiss() {
            this.show = false;
            //this.$dispatch("dismiss", { payload: 'yes' });
            this.personVisible = false;
        },

        async fetchPerson( newUrl ) {
            this.loading = true;

            try {
                const { data: response } = await axios.get(newUrl);
                this.content = response;
            } catch (error) {
                this.content = `<div class="bg-white">Sorry, there was a problem finding this person</div>`;
            } finally {
                this.loading = false;
            }
        }
    }
}