import cardClick from "@js/libs/card-click";

export default function(opts = {}) {

    return {

        stacked: false,
        large: false,

        init() {
            if(opts.stacked) this.stacked = opts.stacked;
            const $el = this.$el;
            const $link = this.$refs.link;
            cardClick($el, $link);
        },

        truncate(text, length, clamp = '...') {
            const node = document.createElement('div');
            node.innerHTML = text;
            const content = node.textContent;
            return content.length > length ? content.slice(0, length) + clamp : content;
        }
    }

}