import { docReady, qsa } from "@js/libs/helpers";
import { media } from "@js/parts/media";

docReady(() => {

	const fns = [];

	qsa('[data-responsive-bg]', el => {

		const bgs = {
			'0': el.getAttribute('data-bg-sm'),
			'1': el.getAttribute('data-bg-md'),
			'2': el.getAttribute('data-bg-lg'),
			'3': el.getAttribute('data-bg-xl')
		};

		fns.push(match => {
			let found = '';
			Object.entries(bgs).forEach(([i, val]) => {


				if (`${i}` <= match && val) {
					found = val;
				}

			});

			if (found) {
				el.style.backgroundImage = `url(${found})`;
			}

		});

	});

	const calc = () => {

		fns.forEach(fn => {
			let match = '0';
			if (media.matches('md')) {
				match = '1';
			} else if (media.matches('lg')) {
				match = '2';
			} else if (media.matches('xl')) {
				match = '3';
			}

			fn(match);
		});

	};

	window.addEventListener('resize', calc);
	calc();

});