import axios from "axios";
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//import NewsCardStacked from "@components/content/NewsCardStacked.vue";
//import ButtonRadio from "@components/micro/button-radio.vue";
//import WorkCardSimple from "@components/content/WorkCardSimple.vue";

export default function(opts = {}) {

    const APP_STATEKEY = "APP_SEARCH";

    return {
        isMobile: false,
        timer: false,
        loading: false,
        results: [],
        searchedQ: '',
        forceLoading: false,
        isModal: false,

        init() {

            this.searchQ = opts.q;
            this.searchSection = opts.section;
            this.isModal = opts.modal;

            window.onpopstate = (e) => {
                const { state } = e;

                if (!state) {
                    window.location.href = window.location.href;
                    return;
                }

                const { _uid, q, stateKey } = state;

                if (stateKey !== APP_STATEKEY) {
                    // it's someone else's state...leave it alone.
                    return;
                }

                if (_uid !== this._uid) {
                    window.location.href = window.location.href;
                    return;
                }

                if (this.isModal && q) {
                    this.searchShow = true;
                    this.searchQ = q;
                    this.search(false);
                } else {
                    this.searchShow = false;
                }
            }

            this.$watch('searchShow', val => {
              if (this.searchShow && this.isModal) {
                  this.$refs.search.focus();
                  document.body.classList.add('overflow-hidden');
              } else if (this.isModal) {
                  document.body.classList.remove('overflow-hidden');
              }
            });

            this.searchedQ = this.searchQ;
            history.replaceState(this.getState(), this.title, window.location.href);

            if (`${this.searchedQ}`.trim() && (!this.isModal || (this.isModal && this.searchShow))) {
                this.search(false);
            }
        },

        /*
        component(entry) {
            if (entry.type === 'news') {
                return 'NewsCardStacked';
            }

            return "WorkCardSimple";
        },

         */

        async search(push = true) {
            if (!`${this.searchQ}`.trim()) {
                return;
            }

            let query = this.searchQ;

            clearTimeout(this.timer);
            this.loading = true;

            const { data: response } = await axios.get('/search.json', {
                params: {
                    q: query,
                    section: this.searchSection,
                }
            })
                .catch(err => {
                    this.loading = false;
                    console.error("Couldn't get posts");
                });


            if (this.searchQ !== query) {
                // Something else searched...
                return;
            }

            this.loading = false;

            const { data, meta } = response;

            this.searchedQ = this.searchQ;

            if (data) {
                this.results = [...data];
                if (push) {
                    this.pushState();
                }
            }

        },

        maybeSearch() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.search();
            }, 200);
        },

        getState() {
            return { _uid: this._uid, q: this.searchedQ, section: this.searchSection, stateKey: APP_STATEKEY };
        },

        pushState() {
            const url = `/search?q=${this.searchQ}&section=${this.searchSection}`;
            history.pushState(this.getState(), this.title, url);
        },

        showOnly(section) {
            this.searchSection = section;
            if (this.searchedQ) {
                this.search();
            }
        },

        get title() {
            if (this.searchedQ) {
                return `Searched for "${this.searchedQ}"`;
            }

            return "Looking for something?";
        },

        get news() {
            return this.results.filter(({ type }) => type === 'news');
        },

        get work() {
            return this.results.filter(({ type }) => type === 'work');
        }
    }
}