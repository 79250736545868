export default function() {
    return {
        url: window.location.href,
        output: "",
        failed: false,

        aClass: 'text-black hover:text-blue w-5 h-5 mt-2 mb-2 flex-no-grow flex-no-shrink',
        svgClass: 'fill-current w-full h-full',

        select() {
            this.$refs.fallback.select();
        },

        fallback() {
            // Failed, let's show an input and let them copy...
            target.setAttribute('text')
        },

        copy() {
            if ( this.failed ) {
                this.fallback();
                return;
            }

            let target = this.$refs.target;
            target.setAttribute('type', 'text');
            target.select();
            let success;

            try {
                success = document.execCommand('copy');
            } catch (err) {

            }

            if ( success ) {
                this.output = "Link copied";
                setTimeout( () => {
                    this.output = false;
                }, 2000 );
                /* unselect the range */
                target.setAttribute('type', 'hidden');
                window.getSelection().removeAllRanges();
            } else {
                this.output = false;
                this.failed = true;
                this.fallback();
            }
        },

        get shareTwitter() {
            return encodeURI(`https://twitter.com/intent/tweet?text=${window.location.href}`);
        },

        get shareFacebook() {
            return encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`);
        },
    }
}