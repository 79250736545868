export default function(images) {

    return {
        timer: false,
        active: 0,
        activated: [],
        images: null,

        init() {
            if(!images) return;

            this.images = images;
            this.timer = setInterval( () => {
                let newActive = this.active + 1;
                if ( this.active + 1 >= this.images.length ) {
                    newActive = 0;
                }
                this.active = newActive;
            }, 4000 );
        },

        isActivated(i) {
            return ( this.activated.indexOf( i ) > -1 );
        },

        canActivate(i) {
            return this.isActivated(i) || this.active <= i+1;
        }
    }
}