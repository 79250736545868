export default function() {

    return {

        init() {
            this.$watch('videoShow', this.show);

            window.addEventListener('keydown', e => this.onClose(e));
        },

        show(newVal, oldVal) {
            if ( newVal ) {
                this.overlayShowing = true;
            } else {
                this.overlayShowing = false;
            }

            document.body.setAttribute('data-modal-show', newVal );

            if ( this.show ) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        },

        onClose(e) {
            if (this.show && e.key === 'Escape') {
                this.show = false;
            }
        }
    }
}