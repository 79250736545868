import { media } from "@js/parts/media";

export default function() {

  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

  return {
    prefersReduced: false,
    debug: false,
    overlayShowing: false,
    workFiltersShow: false,
    workFiltersSections: [],
    workFiltersActive: false,
    videoUrl: null,
    videoShow: false,
    searchQ: null,
    searchSection: null,
    searchShow: false,
    searchOverlayShowing: false,
    personUrl: null,
    personX: null,
    personY: null,
    personVisible: null,

    mobile: media.matches('mobile'),
    md: media.matches('md'),
    sm: media.matches('sm'),
    lg: media.matches('lg'),
    xl: media.matches('xl'),
    xl2: media.matches('xl2'),
    reducedMotion: media.matches('reducedMotion'),

    init() {
      this.check();
      mediaQuery.addEventListener('change', () => this.check());

      [
        'mobile',
        'md',
        'sm',
        'lg',
        'xl',
        'xl2',
        'reducedMotion',
      ].forEach( k => {
        this[k] = media.matches(k);
        this.$dispatch( k, media.matches(k) );
      });

      [
        'mobile',
        'md',
        'sm',
        'lg',
        'xl',
        'xl2',
        'reducedMotion',
      ].forEach( k => media.on( k, (matches) => {
        this[k] = matches;
        this.$dispatch( k, matches );
      }) );
    },

    check() {
      if (mediaQuery.matches) {
        this.prefersReduced = true;
      } else {
        this.prefersReduced = false;
      }

      this.$dispatch('change', this.prefersReduced);
    }
  }

}