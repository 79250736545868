export default function() {
    return {
        open(e) {
            if ( e.which === 2 || e.ctrlKey || e.metaKey ) {
                return;
            }

            let url = this.$el.getAttribute('data-open-person');
            if ( !url ) {
                url = this.$el.getAttribute('href');
            }

            if ( !url ) {
                return;
            }

            e.preventDefault();
            this.personUrl = url;
            this.personX = e.clientX;
            this.personY = e.clientY;
            this.personVisible = true;
        }
    }
}