
import { docReady } from "@js/libs/helpers";
import { media } from "@js/parts/media";

let header;
let elNav;
let elLogo;
const elSearch = document.getElementById('header-search-trigger');
let locked = false;
let lastScrollY = false;
let scrollBy = false;
let totalMovement = 0;
let max = 200 * 2;
let body = false;
const min = 0;

// Set hamburger to false if it doesnt' match MD (i.e. if it's under md);
let isHamburger = !media.matches('md');
media.on('md', md => isHamburger = !md );

let reducedMotion;
media.on('reducedMotion', matches => {
	reducedMotion = matches;
	locked = reducedMotion;
}, true );

let invertLogo;
let invertNav;

if ( 'scrollSettings' in window && typeof window.scrollSettings === 'object') {
	invertLogo = window.scrollSettings.invertLogo;
	invertNav = window.scrollSettings.invertNav;
};

const limit = function (n) {
	return Math.min(max, Math.max(min, n));
};

let state = "top";

const stuck = () => {
	if (state === "top") {
		state = "stuck";
		header.classList.remove('at-top');
		header.setAttribute('data-state', "stuck");
	}
};

const unstuck = () => {
	if (state === "stuck") {
		state = "top";
		header.classList.add('at-top');
		header.setAttribute('data-state', "top");
	}
};

const testEl = ( el, invert ) => {
	if ( !el ) { return; }

	if ( !invert && invert !== 0 ) {
		return;
	}

	if ( window.pageYOffset < invert ) {
		el.classList.add('text-inherit');
		el.classList.remove('text-black');
	} else {
		el.classList.add('text-black');
		el.classList.remove('text-inherit');
	}
};

const resetEl = el => {
	el.classList.remove('text-black');
	el.classList.remove('text-white');
};


const paint = () => {
	// Do as little as possible
	if ( lastScrollY === window.pageYOffset ) { return; }

	totalMovement = limit(totalMovement + (window.pageYOffset - scrollBy));
	scrollBy = Math.max(0, window.pageYOffset);

	if ( locked ) {
		header.style.transform = `translateY(0px)`;
		return;
	}

	if ( isHamburger && body && body.classList.contains('js-open') ) { 
		header.style.transform = `translateY(0px)`;
		return;
	}

	max = header.offsetHeight * 2;

	let y = -0.5 * totalMovement;
	header.style.transform = `translateY(${y}px)`;

	if (window.pageYOffset > 400 ) {
		stuck();
	} else {
		unstuck();
	}

	if ( isHamburger ) {
		resetEl( elLogo );
		resetEl( elNav );
		resetEl( elSearch );
	} else {
		testEl( elLogo, invertLogo );
		testEl( elNav, invertNav );
		testEl( elSearch, invertNav );
	}

	lastScrollY = window.pageYOffset;
};

export const lockHeader = () => {
	if ( header ) {
		header.style.transform = `translateY(0px)`;
		locked = true;
	}
};

export const unlockHeader = () => {
	if ( header ) {
		locked = false;
	}
};

const loop = () => {
	requestAnimationFrame( () => {
		paint();
		loop();
	});
};

export const initHeader = () => {
	docReady(() => {

		body = document.querySelector('body');

		header = document.getElementById('site-header');
		
		elNav = document.getElementById('site-header__nav');
		elLogo = document.getElementById('site-header__logo');

		window.header = header;

		if (!header) {
			return;
		}

		loop();

	});
};

export default { initHeader, unlockHeader, lockHeader };
