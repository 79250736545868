
import { qsa,docReady } from "@js/libs/helpers";
import { media } from "@js/parts/media";

docReady( () => {

	let reducedMotion;
	media.on('reducedMotion', matches => reducedMotion = matches, true );

	const fn = entries => {
		entries.forEach( ({ isIntersecting, target }) => {

			if (isIntersecting) {
				activate( target );
			} else {				
				deactivate( target );

			}

		});
	};

	const observer = new IntersectionObserver( fn, {
		rootMargin: '0px 0px 100px 0px'
	});

	let paintUpFns = {

	};

	let uid = 0;
	const _uuid = () => {
		uid += 1;
		return uid;
	};

	const makeFn = ( target, id ) => {
		let lastY;
		let movement;
		let offset;
		const factor = 0.25;
		const reset = () => {
			lastY = false;
			movement = 0;
			offset = 100;
		};

		reset();
		
		return {
			reset,
			fn: () => {
			
				if ( lastY === false ) {
					lastY = window.pageYOffset;
				}
				
				movement += factor * ( Math.abs( window.pageYOffset - lastY ) );
				const y = Math.min( offset, Math.max( 0, offset - movement ) );
				target.style.transform = `translateY(${ y * 0.05 }vh)`;


				// if ( y === 0 ) {
				// 	unactivate( id );
				// }

				lastY = window.pageYOffset;
			}
		}
	};

	const activate = target => {	
		
		target.classList.add('in-view');
		const { uid } = target.dataset;
		if ( uid && paintUpFns[ uid ] ) {
			paintUpFns[ uid ].reset();
			paintUpFns[ uid ].active = true;
		}
	};
	
	const deactivate = target => {
		// target.classList.remove('in-view');
		const { uid } = target.dataset;
		if ( uid && paintUpFns[ uid ] ) {
			paintUpFns[ uid ].reset();
			paintUpFns[ uid ].active = false;
		}
	};

	let transitionCount = 0;

	qsa('[data-parallax]', target => {
		
		transitionCount += 1;
		observer.observe( target );

		if ( !target.dataset.uid ) {
			const uid = _uuid();
			target.dataset.uid = uid;
		}

		const { reset, fn } = makeFn( target, uid );
	
		paintUpFns[ uid ] = {
			fn,
			reset,
			uid,
			active: false
		}
	
	});

	qsa('[data-transition]', target => {
		observer.observe( target );
	});

	const paintUps = () => {
		Object.entries( paintUpFns ).forEach( ([id, paintup ]) => {
			if ( paintup.active ) {
				paintup.fn();
			}
		});
	};

	const dp = document.getElementById('wrdp');
	if ( dp || transitionCount > 0 ) {
		const loop = () => {
			requestAnimationFrame( () => {
				if ( dp ) {
					dp.style.transform = `translateY(${Math.min( 0, window.pageYOffset * -0.125)}px)`;
				}
				paintUps();

				// Just kill it. Don't put it back, no need.
				if ( !reducedMotion ) {
					loop();
				}
			} );
		};
		loop();
	}
    
});