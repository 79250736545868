import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';
import app from './app';
import video from './video';
import workCategory from './work-category';
import fadeShow from './fade-show';
import slideshow from './slideshow';
import videoOverlay from './video-overlay';
import newsCard from "./news-card";
import moreStories from './more-stories';
import search from './search';
import searchOverlay from './search-overlay';
import shareWidget from "./share-widget";
import peopleOverlay from "./people-overlay";
import personLink from "./personLink";

/* plugins */
Alpine.plugin(intersect);
Alpine.plugin(focus);

/* data components */
Alpine.data("app", app);
Alpine.data("video", video);
Alpine.data("workCategory", workCategory);
Alpine.data("fadeShow", fadeShow);
Alpine.data("slideshow", slideshow);
Alpine.data("videoOverlay", videoOverlay);
Alpine.data("newsCard", newsCard);
Alpine.data("moreStories", moreStories);
Alpine.data("search", search);
Alpine.data("searchOverlay", searchOverlay);
Alpine.data("shareWidget", shareWidget);
Alpine.data("peopleOverlay", peopleOverlay);
Alpine.data("personLink", personLink);

/* start your engines */
window.Alpine = Alpine;
Alpine.start();

